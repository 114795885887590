import { Container, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Eventstable from './eventstable/eventstable';
import { useSelector, useDispatch } from 'react-redux';
import { getHomeEvents, setTabValue } from './eventSlice';

const { REACT_APP_API_URL } = process.env;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export function Events() {


    const tabValue = useSelector(state => state.homeEvents.tabValue)
    const mounted = useSelector(state => state?.homeEvents?.mounted);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!mounted) {
            getEventData();
        }
    });


    const handleChange = (event, newValue) => {
        dispatch(setTabValue({ tabValue: newValue }));
    };


    const getEventData = () => {
        fetch(`${REACT_APP_API_URL}championship-national-events`, {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    dispatch(getHomeEvents(responseJson.data));
                } else {
                    console.log(responseJson);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <Container className='containerFe'>
                <div className='eventsWrapper'>
                    <Box sx={{ display: 'flex' }} className='eventsBox'>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tabValue}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ minWidth: '400px' }}
                            className='tabsWrapper'
                        >
                            <Tab label="Championships" {...a11yProps(0)} className='tabMenu' />
                            <Tab label="Nationals" {...a11yProps(1)} className='tabMenu' />
                        </Tabs>
                        <TabPanel value={tabValue} index={0} className='tabPanelWrapper'>
                            <Eventstable />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className='tabPanelWrapper'>
                            <Eventstable />
                        </TabPanel>
                    </Box>
                </div>
            </Container>
        </>
    )
}

export default Events;