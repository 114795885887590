import { Container, Grid } from '@mui/material';
import React from 'react';
import Getintouch from '../getintouch/getintouch';
import Newsletter from '../newsletter/newsletter';
import Quicklinks from '../quicklinks/quicklinks';
import purpletwitterIcon from '../../assets/images/purple-twitter-icon.svg';
import purplefacebookIcon from '../../assets/images/purple-faceboook-icon.svg';
import purpleinstagramIcon from '../../assets/images/purple-instagram-icon.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function Footer() {
    const navigate = useNavigate();

    const footerLogo = useSelector(state => state?.header?.websiteSettings?.footer_logo)
    const copyright = useSelector(state => state?.header?.websiteSettings?.copyright_content)
    const facebookLink = useSelector(state => state?.header?.websiteSettings?.facebook_link)
    const twitterLink = useSelector(state => state?.header?.websiteSettings?.twitter_link)
    const instagramLink = useSelector(state => state?.header?.websiteSettings?.instagram_link)

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
    };

    const scrollTopAndNavigate = (link) =>{
        scrollToTop();
        navigate(`${link}`);
    };
    return (
        <>
            <footer>
                <Container className='containerFe'>
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={4} sm={6} xs={12}><img src={footerLogo} alt='' className='footerLogo' /></Grid>
                        <Grid item lg={2} md={3} sm={6} xs={12}>
                            <Quicklinks />
                        </Grid>
                        <Grid item lg={4} md={5} sm={6} xs={12}>
                            <Newsletter />
                        </Grid>
                        <Grid item lg={3} sm={6} xs={12}>
                            <Getintouch />
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container spacing={2}>
                        <Grid item lg={3} sm={12} xs={12}></Grid>
                        <Grid item lg={6} sm={7} xs={12}>
                            <ul className='footerLinks'>
                                <li><a style={{ cursor: 'pointer' }} onClick={() => scrollTopAndNavigate('/privacy-policy')}>Privacy Policy</a></li>
                                <li>{copyright}</li>
                            </ul>
                        </Grid>
                        <Grid item lg={3} sm={5} xs={12}>
                            <ul className='socialLinks'>
                                <li><a href={twitterLink} target='_blank' rel="noreferrer"><img src={purpletwitterIcon} alt='' /></a></li>
                                <li><a href={facebookLink} target='_blank' rel="noreferrer"><img src={purplefacebookIcon} alt='' /></a></li>
                                <li><a href={instagramLink} target='_blank' rel="noreferrer"><img src={purpleinstagramIcon} alt='' /></a></li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        </>
    )
}

export default Footer
